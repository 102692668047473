<template>
  <div id="RelatoriosManutencaoModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Duplicar Relatório</span>
          <v-btn
          @click="fecha_dialog"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
                ref="form"
                v-model="valid">

                <v-text-field
                  v-model="dados.nome"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Título do Relatório"
                  placeholder="Título do Relatório"
                  filled
                  required
                  dense
                  :rules="tituloRules"
                ></v-text-field>

                <v-textarea
                  v-model="dados.descricao"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Descrição do Relatório"
                  placeholder="Descrição do Relatório"
                  filled
                  required
                  dense
                  :rules="descricaoRules"
                ></v-textarea>

                <v-text-field
                  v-model="dados.menu_nivel1"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Menu (Nível 1)"
                  placeholder="Menu (Nível 1)"
                  filled
                  required
                  dense
                  :rules="menu_nivelRules"
                ></v-text-field>

                <v-text-field
                  v-model="dados.menu_nivel2"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Menu (Nível 2)"
                  placeholder="Menu (Nível 2)"
                  filled
                  dense
                ></v-text-field>

                <v-select
                  v-model="dados.situacao"
                  label="Situação"
                  placeholder="Situação"
                  class="mt-n2"
                  filled
                  dense
                  background-color="#FFF"
                  :items="['Inativo','Ativo']"
                  item-text="situacao"
                  :rules="situacaoRules"
                ></v-select>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="fecha_dialog"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import { COR_PRINCIPAL } from "../../services/constantes";
import { API } from "../../services/API"
import { EventBus } from "../../services/eventbus";
import store_site from "../../store/store_site";

export default {
  name: "RelatoriosManutencaoModal",

  props: [
    'dados_duplicar','dialog_duplicar','acao_duplicar'],

  data() {
    return {

      store_site        : store_site,

      COR_PRINCIPAL     : COR_PRINCIPAL,

      form              : false,
      valid             : false,

      dados             : this.dados_duplicar,
      acao              : this.acao_duplicar,

      tituloRules: [
        (value) => !!value || "O Título é obrigatório",
        //(value) => /^\d+$/.test(value) || 'O Título não é válido',
        (value) =>
          (value && value.length <= 40) ||
          "O número deve ter menos do que 40 caracteres",
      ],
      descricaoRules: [
        (value) => !!value || "A Descrição é obrigatória",
        (value) =>
          (value && value.length <= 200) ||
          "A Descrição deve ter menos do que 200 caracteres",
      ],
      situacaoRules: [
        (value) => !!value || "A situação é obrigatória",
      ],
      menu_nivelRules: [
        (value) => !!value || "O Nível de Menu é obrigatório",
        (value) =>
          (value && value.length <= 30) ||
          "O Nível de Menu deve ter menos do que 30 caracteres",
      ],

    };
  },

  mounted() {
    console.log(this.dados_duplicar);
  },

  methods: {
    async validate () {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {

        // Editando ----------------
        if (this.dados) {
          let ls_Rel = null;
          if (this.acao == 'E') {
            const lo_JSON = { cod_relatorio           : this.dados.cod_relatorio,
                              nome                    : this.dados.nome,
                              descricao               : this.dados.descricao,
                              conteudo_stimulsoft     : this.dados.conteudo_stimulsoft,
                              situacao                : this.dados.situacao,
                              menu_nivel1             : this.dados.menu_nivel1,
                              menu_nivel2             : this.dados.menu_nivel2,
                          };
            ls_Rel = await API.put(`relatorio_editar`, JSON.stringify(lo_JSON));
          }
          else if (this.acao == 'I') {
            const lo_JSON = { nome                  : this.dados.nome,
                              descricao             : this.dados.descricao,
                              conteudo_stimulsoft   : JSON.stringify(this.dados.conteudo_stimulsoft),
                              situacao              : this.dados.situacao,
                              menu_nivel1           : this.dados.menu_nivel1,
                              menu_nivel2           : this.dados.menu_nivel2,
                            };
            ls_Rel = await API.post(`relatorio_duplicar`, JSON.stringify(lo_JSON));
            //console.log("ls_Rel", ls_Rel);
          }

          if (ls_Rel.status == 200) {
            //this.store_Contato.ContatoGet({});
            this.$emit('update:dialog_duplicar', false);
            EventBus.$emit("lista_relatorios");
            this.store_site.alert_cor       = "#00A000",
            this.store_site.alert_timeout   = 1500,
            this.store_site.alert_msg       = ls_Rel.data.result.result.trim();;
            this.store_site.alert           = true;
          } else {
            this.store_site.alert_cor       = "#FF0000",
            this.store_site.alert_timeout   = 10000,
            this.store_site.alert_msg       = ls_Rel.data.errors.trim();
            this.store_site.alert           = true;
          }

        }
      }
    },

    fecha_dialog() {
      this.$emit('update:dialog_duplicar', false);
    }
  },
  
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 460px;
  overflow-y: auto;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.btn {
  width: 100px;
}
</style>